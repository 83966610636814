<template>
	<div class="container">
		<h6 class="header">Отчет об изменениях капитала <q-input class="inline" dense flat v-model="document_date">
				<template v-slot:append>
					<q-icon name="event" class="cursor-pointer">
						<q-popup-proxy cover transition-show="scale" transition-hide="scale">
							<q-date mask="DD.MM.YYYY" v-model="document_date"
								@update:model-value="(newValue) => onDateChanged(newValue)">
								<div class="row items-center justify-end">
									<q-btn v-close-popup label="Закрыть" color="primary" flat />
								</div>
							</q-date>
						</q-popup-proxy>
					</q-icon>
				</template>
			</q-input></h6>
		<div class="row" style="width: 180mm;">
			<div class="col-8">
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Организация" class="inline" style="width: 100%;" dense flat
						v-model="organisation_name" />
				</p>
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Идентификационный номер налогоплательщика" class="inline" style="width: 100%;" dense
						flat v-model="organisation_inn" />
				</p>
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Вид экономической деятельности" class="inline" style="width: 100%;" dense flat
						v-model="organisation_econ" />
				</p>
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Организационно-правовая форма/форма собственности" class="inline"
						style="width: 100%;" dense flat v-model="organisation_form" />
				</p>
				<p style="width: 100%; padding-right: 10mm">Единица измерения: <q-select v-model="currency_measure"
						:options="currency_measure_options" class="inline" dense flat />
				</p>
			</div>
			<div class="col-4">
				<table
					style="table-layout: fixed; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
					<tr>
						<td colspan="1"
							style="border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
						</td>
						<td colspan="6" style="width: 30mm; text-align: center;">Коды</td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Форма по ОКУД
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Дата (число, месяц, год)
						</td>
						<td colspan="2"><q-input dense flat class="inline" /></td>
						<td colspan="2"><q-input dense flat class="inline" /></td>
						<td colspan="2"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКПО
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							ИНН
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКВЭД 2
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКОПФ/ОКФС
						</td>
						<td colspan="3"><q-input dense flat class="inline" /></td>
						<td colspan="3"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКЕИ
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
				</table>
			</div>
		</div>
		<div class="section">
			<div style="width: 100%; text-align: center;font-weight: bold;">
				1. Движение капитала
			</div>

			<table class="table-cap" style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr style="font-size: 8pt; word-break: break-all;">
						<th style="width: 60mm;">Наименование показателя</th>
						<th style="width: 20mm;">Уставной капитад</th>
						<th style="width: 20mm;">Собственные акции, выкупленные у акционеров</th>
						<th style="width: 20mm;">Добавочный капитал</th>
						<th style="width: 20mm;">Резервный капитал</th>
						<th style="width: 20mm;">Нераспределенная прибыль (непокрытый убыток)</th>
						<th style="width: 20mm;">Итого</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Величина капитала на 31 декабря 2024г.</td>
						<td></td>
						<td>()</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>За 20г.<br />Увеличение капитала всего</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>В том числе:<br />чистая прибыль</td>
						<td>Х</td>
						<td>Х</td>
						<td>Х</td>
						<td>Х</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>переоценка имущества</td>
						<td>Х</td>
						<td>Х</td>
						<td></td>
						<td>Х</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>доходы, относящиеся непосредственно на увеличение капитала</td>
						<td>Х</td>
						<td>Х</td>
						<td></td>
						<td>Х</td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Дополнительный выпуск акций</td>
						<td></td>
						<td></td>
						<td></td>
						<td>Х</td>
						<td>Х</td>
						<td></td>
					</tr>
					<tr>
						<td>Увеличение номинальной стоимости акций</td>
						<td></td>
						<td></td>
						<td></td>
						<td>Х</td>
						<td></td>
						<td>Х</td>
					</tr>
					<tr>
						<td>реорганизация номинальной стоимости акции</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Уменьшение капитала - всего</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>в том числе:<br />убыток</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>переоценка имущества</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>расходы, относящиеся непосредственно на уменьшение капитала</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>уменьшение номинальной стоимости акций</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>уменьшение количества акций</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>реорганизация юридического лица</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>дивиденды</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Изменение добавочного капитала</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Изменение резервного капитала</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Величина капитала на 31 декабря 2024</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>За 20 г <br />Увеличение капитала - всего:</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>в том числе:<br /> чистая прибыль</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>переоценка имущества</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>доходы, относящиеся непосредственно на увеличение капитала</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>дополнительный выпуск акций</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>увеличение номинальной стоимости акций</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>реорганизация юридического лица</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Уменьшение капитала - всего:</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>в том числе: <br /> убыток</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>переоценка имущества</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>расходы, относящиеся непосредственно на уменьшение капитала</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>уменьшение номинальной стоимости акций</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>уменьшение количества акций</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>реорганизация юридического лица</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>дивиденды</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Изменение добавочного капитала</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Изменение резервного капитала</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>Величина капитала на 31 декабря 2024 г</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
				</tbody>
			</table>
		</div>

		<div class="section">
			<div style="width: 100%; text-align: center;font-weight: bold;">
				2. Корректировки в связи с изменением учетной политики и исправлением ошибок
			</div>

			<table class="table-cap" style="table-layout: fixed; max-width: 180mm; ">
				<thead>
					<tr style="font-size: 8pt; word-break: break-all;">
						<th rowspan="2" style="width: 60mm;">Наименование показателя</th>
						<th rowspan="2" style="width: 30mm;">На 31 декабря 2024 г. </th>
						<th rowspan="1" colspan="2" style="width: 60mm;">Изменения капитала за 2024 г.</th>
						<th rowspan="2" style="width: 30mm;">На 31 декабря 2024 г.</th>
					</tr>
					<tr style="font-size: 8pt; word-break: break-all;">
						<th rowspan="1" style="width: 30mm;">за счет чистой прибыли (убытка)</th>
						<th rowspan="1" style="width: 30mm;">за счет иных факторов</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Капитал - всего
							<br />до корректировок
						</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>корректировка в связи с:
							<br />изменением учетной политики
						</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>исправлением ошибок</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>после корректировок</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>в том числе:<br />нераспределенная прибыль (непокрытый убыток):<br />до корректировок</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>корректировка в связи с:<br />изменением учетной политики</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>исправлением ошибок</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>после корректировок</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>другие статьи капитала, по которым осуществлены корректировки:<br />до корректировок</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>корректировка в связи с:<br />изменением учетной политики</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>исправлением ошибок</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
					<tr>
						<td>после корректировок</td>
						<td></td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="section">
			<div style="width: 100%; text-align: center;font-weight: bold;">
				2. Корректировки в связи с изменением учетной политики и исправлением ошибок
			</div>

			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr style="font-size: 8pt; word-break: break-all;">
						<th style="width: 60mm;">Наименование показателя</th>
						<th style="width: 40mm;">На none 2024г.</th>
						<th style="width: 40mm;">На none 2024г.</th>
						<th style="width: 40mm;">На none 2024г.</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							Чистые активы
						</td>
						<td></td>
						<td></td>
						<td></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div style="margin-top: 10mm;">
		<q-btn color="primary" icon="save" label="Сохранить" @click="onSave" />
	</div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

const currency_measure_options = [
	{ label: 'тыс.руб.', value: 'th_rub' },
];

export default {
	name: "create_document_report_cap_change",
	data() {
		this.emitter.on('didCreateNewDocument', (data) => {
			console.log(data);
			this.$q.loading.hide();
			if (data.error) {
				this.$q.dialog({
					title: 'Ошибка',
					message: 'Документ не был создан',
					cancel: false,
					persistent: true
				}).onOk(() => {
				}).onCancel(() => {
					console.log('cancel')
				}).onDismiss(() => {
					console.log('dismiss')
				})
			} else {
				this.$q.notify('Документ был создан');
				this.$router.push({ name: 'reports_list', params: {} });
			}
		})
		this.emitter.on('didReceiveNewDocumentNumber', (data) => {
			this.in_org_number = data.number;
			this.step = 2;
		});
		this.emitter.on('didReceiveAgentInfoByInn', (data) => {
			console.log(data, 'agent')
			if (data.request_id === 'rec_inn') {
				this.rec = data.info.name.short_with_opf;
				this.rec_kpp = data.info.kpp;
			}
			if (data.request_id === 'plat_inn') {
				this.plat = data.info.name.short_with_opf;
				this.plat_kpp = data.info.kpp;
			}
		})
		if (this.connection.isAuthenticated) {
			this.connection.getNewDocumentNumber(
				'1',
				this.$store.state.current_org_id,
				'report'
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getNewDocumentNumber(
					'1',
					this.$store.state.current_org_id,
					'report'
				);
			})
		}
		return {
			step: ref(1),
			document_type: ref('report'),
			in_org_number: ref(null),
			organisation_name: ref(null),
			organisation_inn: ref(null),
			organisation_econ: ref(null),
			organisation_form: ref(null),
			document_date_unix: ref(0),
			document_date: ref(null),
			currency_measure_options: ref(currency_measure_options),
			currency_measure: ref(currency_measure_options[0]),
			profit: ref({ current: 0, previous: 0, desc: '' }),
			sebest: ref({ current: 0, previous: 0, desc: '' }),
			val_profit: ref({ current: 0, previous: 0, desc: '' }),
			comm_costs: ref({ current: 0, previous: 0, desc: '' }),
			man_costs: ref({ current: 0, previous: 0, desc: '' }),
			sell_profit: ref({ current: 0, previous: 0, desc: '' }),
			another_org_prof: ref({ current: 0, previous: 0, desc: '' }),
			take_percent: ref({ current: 0, previous: 0, desc: '' }),
			make_perc: ref({ current: 0, previous: 0, desc: '' }),
			another_profit: ref({ current: 0, previous: 0, desc: '' }),
			another_costs: ref({ current: 0, previous: 0, desc: '' }),
			profit_gross: ref({ current: 0, previous: 0, desc: '' }),
			profit_tax: ref({ current: 0, previous: 0, desc: '' }),
			current_tax_profit: ref({ current: 0, previous: 0, desc: '' }),
			delayed_tax_profit: ref({ current: 0, previous: 0, desc: '' }),
			another: ref({ current: 0, previous: 0, desc: '' }),
			clear_profit: ref({ current: 0, previous: 0, desc: '' }),
			activ_rez: ref({ current: 0, previous: 0, desc: '' }),
			rez_another_operations: ref({ current: 0, previous: 0, desc: '' }),
			tax_operations: ref({ current: 0, previous: 0, desc: '' }),
			total_fin_rez: ref({ current: 0, previous: 0, desc: '' }),
			basic_profit_oblig: ref({ current: 0, previous: 0, desc: '' }),
			profit_oblig: ref({ current: 0, previous: 0, desc: '' }),
		}
	},
	methods: {
		onDateChanged(newValue) {
			this.document_date_unix = moment(newValue, 'DD.MM.YYYY').unix()
		},
		checkAgentBy(bik_val, field_id) {
			const bik = bik_val.replace(/\s/g, "");
			console.log(bik, field_id)
			if (bik.length < 9) {
				return;
			}
			this.connection.getAgentInfoByBik(
				field_id,
				bik
			);
		},
		checkAgentByInn(inn_val, field_id) {
			const inn = inn_val.replace(/\s/g, "");
			console.log(inn, field_id)
			if (inn.length < 9) {
				return;
			}
			this.connection.getAgentInfoByInn(
				field_id,
				inn
			);
		},
		formatDate(date) {
			return moment.unix(date).format('DD.MM.YYYY');
		},
		onSave() {
			const body = {
				organisation_name: this.organisation_name,
				organisation_inn: this.organisation_inn,
				organisation_econ: this.organisation_econ,
				organisation_form: this.organisation_form,
				organisation_address: this.organisation_address,
				organisation_audit_id: this.organisation_audit_id,
				organisation_audit_name: this.organisation_audit_name,
				organisation_audit_num: this.organisation_audit_num,
				audit: this.audit,
				currency_measure: this.currency_measure,
				profit: this.profit,
				sebest: this.sebest,
				val_profit: this.val_profit,
				comm_costs: this.comm_costs,
				man_costs: this.man_costs,
				sell_profit: this.sell_profit,
				another_org_prof: this.another_org_prof,
				take_percent: this.take_percent,
				make_perc: this.make_perc,
				another_profit: this.another_profit,
				another_costs: this.another_costs,
				profit_gross: this.profit_gross,
				profit_tax: this.profit_tax,
				current_tax_profit: this.current_tax_profit,
				delayed_tax_profit: this.delayed_tax_profit,
				another: this.another,
				clear_profit: this.clear_profit,
				activ_rez: this.activ_rez,
				rez_another_operations: this.rez_another_operations,
				tax_operations: this.tax_operations,
				total_fin_rez: this.total_fin_rez,
				basic_profit_oblig: this.basic_profit_oblig,
				profit_oblig: this.profit_oblig,
				document_date: this.document_date_unix,
				in_org_number: this.in_org_number,
				document_type: 'report',
				report_type: 'fin_rez'
			};
			this.$q.loading.show();
			this.connection.createNewDocument(
				'3',
				this.$store.state.current_org_id,
				body
			);

		}

	}
}
</script>

<style scoped>
@page {
	size: A4;
	margin: 0;
}

.container {
	margin: 0;
	padding: 0;
	font-family: 'Times New Roman', Times, serif;
	font-size: 12px;
	background: white;
	color: black;
	/* width: 210mm;
	height: 297mm; */
	padding: 20mm;
	box-sizing: border-box;
	display: block;
	margin: auto;
	position: relative;
}


.header {
	text-align: center;
}

.section {
	margin: 20px 0;
}

table {
	width: 100%;
	border-collapse: collapse;
}

table,
th,
td {
	border: 0.3mm solid black;
	padding: 0px 4px;
	text-align: left;
}
.table-cap table,
th,
td {
	border: 0.3mm solid black;
	padding: 0px 4px;
	text-align: center;
}

.right-align {
	text-align: right;
}

.signature {
	margin-top: 20px;
	text-align: right;
}

@media print {
	@page {
		size: A4;
		margin: 20mm;
	}

	body {
		margin: 0;
		font-size: 12pt;
	}

	table,
	th,
	td {
		border: 1px solid black;
		padding: 5px;
	}

	.header {
		margin-top: 0;
	}
}
</style>
