<template>

	<div class="">
		<q-btn @click="onPrintButtonClick" unelevated color="white" icon-right="download" text-color="primary"
			label="Печать" />
		<q-btn @click="onEditDocument" unelevated color="white" icon-right="edit" text-color="primary"
			label="Проводки и редактирование" />
		<q-btn v-if="is_active" @click="onDeleteDocument" unelevated color="white" icon-right="delete"
			text-color="negative" label="Удалить" />
		<q-btn v-else @click="onRestoreDocument" unelevated color="white" icon-right="history" text-color="primary"
			label="Восстановить" />
	</div>
	<div class="doc landscape upd" id="print_content" style="width: 180mm;">
		<h6 class="header">Отчет о движении денежных средств {{ formatDate(body.date_current) }}г. </h6>
		<div style="width: 182mm; display: flex; flex-wrap: wrap;">
			<div style="display: inline; width: 120mm;">
				<p style="width: 100%; padding-right: 10mm">Организация: {{ body.organisation_name }}
				</p>
				<p style="width: 100%; padding-right: 10mm">Идентификационный номер налогоплательщика: {{
					body.organisation_inn }}
				</p>
				<p style="width: 100%; padding-right: 10mm">Вид экономической деятельности: {{
					body.organisation_econ }}
				</p>
				<p style="width: 100%; padding-right: 10mm">
					Организационно-правовая форма/форма собственности: {{ body.organisation_form }}
				</p>
				<p style="width: 100%; padding-right: 10mm">
					Единица измерения: {{ ((body.currency_measure || { label: '' }).label) }}
				</p>
			</div>
			<div style="display: inline; width: 60mm;">
				<table
					style="table-layout: fixed; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
					<tr>
						<td colspan="1"
							style="border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
						</td>
						<td colspan="6" style="width: 30mm; text-align: center;">Коды</td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Форма по ОКУД
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Дата (число, месяц, год)
						</td>
						<td colspan="2"></td>
						<td colspan="2"></td>
						<td colspan="2"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКПО
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							ИНН
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКВЭД 2
						</td>
						<td colspan="6"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКОПФ/ОКФС
						</td>
						<td colspan="3"></td>
						<td colspan="3"></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКЕИ
						</td>
						<td colspan="6"></td>
					</tr>
				</table>
			</div>
		</div>
		<div class="section">

			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr>
						<th style="width: 90mm;">Наименование показателя</th>
						<th style="width: 45mm;">За {{ formatDate(body.date_current) }}г.</th>
						<th style="width: 45mm;">За {{ formatDate(body.date_previous) }}г.</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							Денежные потоки от текущих операций<br />Поступления - всего
						</td>
						<td>{{ body.total_income.current }}</td>
						<td>{{ body.total_income.previous }}</td>
					</tr>
					<tr>
						<td>
							в том числе:<br />от продажи продукции, товаров, работ и услуг
						</td>
						<td>{{ body.sell_goods.current }}</td>
						<td>{{ body.sell_goods.previous }}</td>
					</tr>
					<tr>
						<td>арендных платежей, лицензионных платежей, роялти, комиссионных и иных аналогичных
							платежей
						</td>
						<td>{{ body.rent_com_payments.current }}</td>
						<td>{{ body.rent_com_payments.previous }}</td>
					</tr>
					<tr>
						<td>от перепродажи финансовых вложений</td>
						<td>{{ body.resell_fin.current }}</td>
						<td>{{ body.resell_fin.previous }}</td>
					</tr>
					<tr>
						<td>прочие поступления
						</td>
						<td>{{ body.other_income.current }}</td>
						<td>{{ body.other_income.previous }}</td>
					</tr>
					<tr>
						<td>Платежи - всего
						</td>
						<td>{{ body.payments_total.current }}</td>
						<td>{{ body.payments_total.previous }}</td>
					</tr>
					<tr>
						<td>в том числе:<br />поставщикам (подрядчикам) за сырье, материалы,
							работы, услуги
						</td>
						<td>{{ body.payments_sender.current }}</td>
						<td>{{ body.payments_sender.previous }}</td>
					</tr>
					<tr>
						<td>в связи с оплатой труда работников
						</td>
						<td>{{ body.payments_salary.current }}</td>
						<td>{{ body.payments_salary.previous }}</td>
					</tr>
					<tr>
						<td>процентов по долговым обязательствам
						</td>
						<td>{{ body.debt_perc.current }}</td>
						<td>{{ body.debt_perc.previous }}</td>
					</tr>
					<tr>
						<td>налога на прибыль организаций</td>
						<td>{{ body.income_tax.current }}</td>
						<td>{{ body.income_tax.previous }}</td>
					</tr>
					<tr>
						<td>прочие платежи</td>
						<td>{{ body.payement_other_paymetns.current }}
						</td>
						<td>{{ body.payement_other_paymetns.previous }}
						</td>
					</tr>
					<tr>
						<td>Сальдо денежных потоков от текущих операций</td>
						<td>{{ body.payment_current_saldo.current }}
						</td>
						<td>{{ body.payment_current_saldo.previous }}
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="section">

			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr>
						<th style="width: 90mm;">Наименование показателя</th>
						<th style="width: 45mm;">За {{ formatDate(body.date_current) }}г.</th>
						<th style="width: 45mm;">За {{ formatDate(body.date_previous) }}г.</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							Денежные потоки от инвестиционных операций<br />Поступления - всего
						</td>
						<td>{{ body.invest_income_total.current }}</td>
						<td>{{ body.invest_income_total.previous }}</td>
					</tr>
					<tr>
						<td>
							в том числе:<br />от продажи внеоборотных активов (кроме финансовых вложений)
						</td>
						<td>{{ body.sell_outbound_fin.current }}</td>
						<td>{{ body.sell_outbound_fin.previous }}</td>
					</tr>
					<tr>
						<td>от продажи акций других организаций (долей участия)
						</td>
						<td>{{ body.inv_sell_papers_others.current }}
						</td>
						<td>{{ body.inv_sell_papers_others.previous }}
						</td>
					</tr>
					<tr>
						<td>от возврата предоставленных займов, от продажи долговых ценных бумаг (прав требования
							денежных средств к другим лицам)</td>
						<td>{{ body.inv_sell_debts.current }}</td>
						<td>{{ body.inv_sell_debts.previous }}</td>
					</tr>
					<tr>
						<td>дивидендов, процентов по долговым финансовым вложениям и аналогичных поступлений от долевого
							участия в других организациях</td>
						<td>{{ body.inv_divid.current }}</td>
						<td>{{ body.inv_divid.previous }}</td>
					</tr>
					<tr>
						<td>прочие поступления
						</td>
						<td>{{ body.inv_other_income.current }}</td>
						<td>{{ body.inv_other_income.previous }}</td>
					</tr>
					<tr>
						<td>Платежи - всего
						</td>
						<td>{{ body.inv_total_payments.current }}</td>
						<td>{{ body.inv_total_payments.previous }}</td>
					</tr>
					<tr>
						<td>в том числе:<br />в связи с приобретением, созданием, модернизацией, реконструкцией и
							подготовкой к использованию внеоборотных активов
						</td>
						<td>{{ body.inv_rec_outbound_fin.current }}</td>
						<td>{{ body.inv_rec_outbound_fin.previous }}</td>
					</tr>
					<tr>
						<td>в связи с приобретением акций других организаций (долей участия)
						</td>
						<td>{{ body.inv_rec_papers.current }}</td>
						<td>{{ body.inv_rec_papers.previous }}</td>
					</tr>
					<tr>
						<td>в связи с приобретением долговых ценных бумаг (прав требования денежных средств к другим
							лицам), предоставление займов другим лицам
						</td>
						<td>{{ body.inv_rec_debts.current }}</td>
						<td>{{ body.inv_rec_debts.previous }}</td>
					</tr>
					<tr>
						<td>процентов по долговым обязательствам, включаемым в стоимость инвестиционного актива</td>
						<td>{{ body.inv_rec_debts_perc.current }}</td>
						<td>{{ body.inv_rec_debts_perc.previous }}</td>
					</tr>
					<tr>
						<td>прочие платежи</td>
						<td>{{ body.inv_other_payments.current }}</td>
						<td>{{ body.inv_other_payments.previous }}</td>
					</tr>
					<tr>
						<td>Сальдо денежных потоков от инвестиционных операций</td>
						<td>{{ body.inv_saldo.current }}</td>
						<td>{{ body.inv_saldo.previous }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div class="section">

			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr>
						<th style="width: 90mm;">Наименование показателя</th>
						<th style="width: 45mm;">За {{ formatDate(body.date_current) }}г.</th>
						<th style="width: 45mm;">За {{ formatDate(body.date_previous) }}г.</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							Денежные потоки от финансовых операций<br />Поступления - всего
						</td>
						<td>{{ body.fin_income_total.current }}</td>
						<td>{{ body.fin_income_total.previous }}</td>
					</tr>
					<tr>
						<td>
							в том числе:<br />получение кредитов и займов
						</td>
						<td>{{ body.fin_cred.current }}</td>
						<td>{{ body.fin_cred.previous }}</td>
					</tr>
					<tr>
						<td>денежных вкладов собственников (участников)
						</td>
						<td>{{ body.fin_invest.current }}</td>
						<td>{{ body.fin_invest.previous }}</td>
					</tr>
					<tr>
						<td>от выпуска акций, увеличения долей участия</td>
						<td>{{ body.fin_create_papers.current }}</td>
						<td>{{ body.fin_create_papers.previous }}</td>
					</tr>
					<tr>
						<td>от выпуска облигаций, векселей и других долговых ценных бумаг и др.</td>
						<td>{{ body.fin_create_oblig.current }}</td>
						<td>{{ body.fin_create_oblig.previous }}</td>
					</tr>
					<tr>
						<td>прочие поступления
						</td>
						<td>{{ body.fin_other_income.current }}</td>
						<td>{{ body.fin_other_income.previous }}</td>
					</tr>
					<tr>
						<td>Платежи - всего
						</td>
						<td>{{ body.fin_payments_total.current }}</td>
						<td>{{ body.fin_payments_total.previous }}</td>
					</tr>
					<tr>
						<td>в том числе:<br />собственникам (участникам) в связи с выкупом у них акций (долей участия)
							организации или их выходом из состава участников
						</td>
						<td>{{ body.fin_pay_exit_paper.current }}</td>
						<td>{{ body.fin_pay_exit_paper.previous }}</td>
					</tr>
					<tr>
						<td>на уплату дивидендов и иных платежей по распределению прибыли в пользу собственников
							(участников)
						</td>
						<td>{{ body.fin_pay_div.current }}</td>
						<td>{{ body.fin_pay_div.previous }}</td>
					</tr>
					<tr>
						<td>в связи с погашением (выкупом) векселей и других долговых ценных бумаг, возврат кредитов и
							займов
						</td>
						<td>{{ body.fin_pay_oblig.current }}</td>
						<td>{{ body.fin_pay_oblig.previous }}</td>
					</tr>
					<tr>
						<td>прочие платежи</td>
						<td>{{ body.fin_pay_other_payments.current }}
						</td>
						<td>{{ body.fin_pay_other_payments.previous }}
						</td>
					</tr>
					<tr>
						<td>Сальдо денежных потоков от финансовых операций</td>
						<td>{{ body.fin_saldo_fin_oper.current }}</td>
						<td>{{ body.fin_saldo_fin_oper.previous }}</td>
					</tr>
					<tr>
						<td>Сальдо денежных потоков за отчетный период</td>
						<td>{{ body.fin_saldo_period.current }}</td>
						<td>{{ body.fin_saldo_period.previous }}</td>
					</tr>
					<tr>
						<td>Остаток денежных средств и денежных эквивалентов на начало отчетного периода</td>
						<td>{{ body.fin_money_remainder_start.current }}
						</td>
						<td>{{ body.fin_money_remainder_start.previous }}
							flat /></td>
					</tr>
					<tr>
						<td>Остаток денежных средств и денежных эквивалентов на конец отчетного периода</td>
						<td>{{ body.fin_money_remainder_end.current }}
						</td>
						<td>{{ body.fin_money_remainder_end.previous }}
						</td>
					</tr>
					<tr>
						<td>Величина влияния изменений курса иностранной валюты по отношению к рублю</td>
						<td>{{ body.fin_money_rub_to_usd.current }}</td>
						<td>{{ body.fin_money_rub_to_usd.previous }}</td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
</template>

<script>
import { ref } from 'vue';
import moment from "moment";

var style = "<style>" +
	"@page {" +
	"size: A4;" +
	"margin: 12;" +
	"}" +
	".container {" +
	"margin: 0;" +
	"padding: 0;" +
	"font-family: 'Times New Roman', Times, serif;" +
	"font-size: 12px;" +
	"background: white;" +
	"color: black;" +
	"/* width: 210mm;" +
	"height: 297mm; */" +
	"padding: 20mm;" +
	"box-sizing: border-box;" +
	"display: block;" +
	"margin: auto;" +
	"position: relative;" +
	"}" +
	".header {" +
	"text-align: center;font-size: 16pt;font - weight: bold;" +
	"}" +
	".section {" +
	"margin: 20px 0;" +
	"}" +
	"table {" +
	"width: 100%;" +
	"border-collapse: collapse;" +
	"}" +
	"table," +
	"th," +
	"td {" +
	"border: 0.3mm solid black;" +
	"padding: 0px 4px;" +
	"text-align: center;" +
	"}" +
	".right-align {" +
	"text-align: right;" +
	"}" +
	".signature {" +
	"margin-top: 20px;" +
	"text-align: right;" +
	"}" +
	"@media print {" +
	"@page {" +
	"size: A4;" +
	"margin: 20mm;" +
	"}" +
	"body {" +
	"margin: 0;" +
	"font-size: 12pt;" +
	"}" +
	"table," +
	"th," +
	"td {" +
	"border: 1px solid black;" +
	"padding: 5px;" +
	"}" +
	".header {" +
	"margin-top: 0;" +
	"}" +
	"}" +
	"</style>";
const currency_measure_options = [
	{ label: 'тыс.руб.', value: 'th_rub' },
];
export default {
	name: "info_document_report_money_using",
	data() {
		this.emitter.on('onDeleteDocument', (response) => {
			console.log(response);
			this.connection.getDocumentInfo(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id,
				'last'
			);
			this.connection.getDocumentInfoShort(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id
			);
		});

		this.emitter.on('onRestoreDocument', (response) => {
			console.log(response);
			this.connection.getDocumentInfo(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id,
				'last'
			);
			this.connection.getDocumentInfoShort(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id
			);
		});
		this.emitter.on('didReceiveDocumentInfo', (response) => {
			// const changes = response.data.changes;
			console.log(response.body);
			this.show = true;
			this.is_active = response.data.is_active;
			this.name = response.data.name;
			this.body = response.data.body;//changes[this.$route.params.change_id].metadata;
			this.changes = response.data.changes;
			this.transfers = response.data.transfers;
			this.$q.loading.hide();
		});
		this.emitter.on('onCreateUpdTransferWildcard', (data) => {
			console.log(data)
			this.connection.getDocumentInfo(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id,
				this.$route.params.history_id
			);
		});
		this.emitter.on('didReceiveBillListFlatCompact', (data) => {
			this.bills_list = data.bills.map((element) => {
				return { label: element.full_name, value: element.id }
			});
		});
		if (this.connection.isAuthenticated) {
			this.connection.getDocumentInfo(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id,
				this.$route.params.history_id
			);
			this.connection.gitBillsListFlatCompact(
				'1',
				this.$store.state.current_org_id,
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentInfo(
					'1',
					this.$store.state.current_org_id,
					this.$route.params.id,
					this.$route.params.history_id
				);
				this.connection.gitBillsListFlatCompact(
					'1',
					this.$store.state.current_org_id,
				);
			})
		}
		return {
			show: ref(false),
			name: ref(''),
			body: ref({
				organisation_name: null,
				organisation_inn: null,
				organisation_econ: null,
				organisation_form: null,
				document_date_unix: 0,
				document_date: null,
				currency_measure_options: currency_measure_options,
				currency_measure: currency_measure_options[0],
				total_income: ref({ current: 0, previous: 0, desc: '' }),
				sell_goods: ref({ current: 0, previous: 0, desc: '' }),
				rent_com_payments: ref({ current: 0, previous: 0, desc: '' }),
				resell_fin: ref({ current: 0, previous: 0, desc: '' }),
				other_income: ref({ current: 0, previous: 0, desc: '' }),
				payments_total: ref({ current: 0, previous: 0, desc: '' }),
				payments_sender: ref({ current: 0, previous: 0, desc: '' }),
				payments_salary: ref({ current: 0, previous: 0, desc: '' }),
				debt_perc: ref({ current: 0, previous: 0, desc: '' }),
				income_tax: ref({ current: 0, previous: 0, desc: '' }),
				payement_other_paymetns: ref({ current: 0, previous: 0, desc: '' }),
				payment_current_saldo: ref({ current: 0, previous: 0, desc: '' }),
				invest_income_total: ref({ current: 0, previous: 0, desc: '' }),
				sell_outbound_fin: ref({ current: 0, previous: 0, desc: '' }),
				inv_sell_papers_others: ref({ current: 0, previous: 0, desc: '' }),
				inv_sell_debts: ref({ current: 0, previous: 0, desc: '' }),
				inv_divid: ref({ current: 0, previous: 0, desc: '' }),
				inv_other_income: ref({ current: 0, previous: 0, desc: '' }),
				inv_total_payments: ref({ current: 0, previous: 0, desc: '' }),
				inv_rec_outbound_fin: ref({ current: 0, previous: 0, desc: '' }),
				inv_rec_papers: ref({ current: 0, previous: 0, desc: '' }),
				inv_rec_debts: ref({ current: 0, previous: 0, desc: '' }),
				inv_rec_debts_perc: ref({ current: 0, previous: 0, desc: '' }),
				inv_other_payments: ref({ current: 0, previous: 0, desc: '' }),
				inv_saldo: ref({ current: 0, previous: 0, desc: '' }),
				fin_income_total: ref({ current: 0, previous: 0, desc: '' }),
				fin_cred: ref({ current: 0, previous: 0, desc: '' }),
				fin_invest: ref({ current: 0, previous: 0, desc: '' }),
				fin_create_papers: ref({ current: 0, previous: 0, desc: '' }),
				fin_create_oblig: ref({ current: 0, previous: 0, desc: '' }),
				fin_other_income: ref({ current: 0, previous: 0, desc: '' }),
				fin_payments_total: ref({ current: 0, previous: 0, desc: '' }),
				fin_pay_exit_paper: ref({ current: 0, previous: 0, desc: '' }),
				fin_pay_div: ref({ current: 0, previous: 0, desc: '' }),
				fin_pay_oblig: ref({ current: 0, previous: 0, desc: '' }),
				fin_pay_other_payments: ref({ current: 0, previous: 0, desc: '' }),
				fin_saldo_fin_oper: ref({ current: 0, previous: 0, desc: '' }),
				fin_saldo_period: ref({ current: 0, previous: 0, desc: '' }),
				fin_money_remainder_start: ref({ current: 0, previous: 0, desc: '' }),
				fin_money_remainder_end: ref({ current: 0, previous: 0, desc: '' }),
				fin_money_rub_to_usd: ref({ current: 0, previous: 0, desc: '' }),
				date_current: 0,
				date_previous: 0,
				date_preprevious: 0
			}),
			is_active: ref(true),
			transfers: ref([]),
			changes: ref([]),
			document_id: ref(this.$route.params.id),
			bills_list: ref([]),
			wildcard_summ: ref(null),
			wildcard_name: ref(null),
			wildcard_comment: ref(null),
			wildcard_bill_from: ref(null),
			wildcard_bill_to: ref(null)
		}
	},
	methods: {
		onTransferStateChange(value, item_id) {
			this.connection.updateTransferActiveState(
				'1',
				this.$store.state.current_org_id,
				item_id,
				value
			)
		},
		onTransferBillChange(value, item_id, direction) {
			this.connection.updateTransferBillState(
				'1',
				this.$store.state.current_org_id,
				item_id,
				value,
				direction
			)
		},
		valueForTable(array, id, key) {
			return array[id][key].label || array[id][key].value
		},
		valueFor(array, key) {
			return array.find(item => item.field_id === key).value;
		},
		formatDate(date) {
			return moment.unix(date).format('DD.MM.YYYY');
		},
		onPrintButtonClick() {
			console.log('onPrintButtonClick');
			let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

			mywindow.document.write(`<html><head><title></title>`);
			mywindow.document.write('</head><body >' + style);
			mywindow.document.write(document.getElementById('print_content').innerHTML);
			mywindow.document.write('</body></html>');

			mywindow.document.close(); // necessary for IE >= 10
			mywindow.focus(); // necessary for IE >= 10*/

			mywindow.print();
			mywindow.close();

			return true;
		},
		createTransferWildcard() {
			this.$q.loading.show();
			this.connection.createUpdTrasferWildcard(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id,
				this.wildcard_summ,
				this.wildcard_name,
				this.wildcard_comment,
				this.wildcard_bill_from.value,
				this.wildcard_bill_to.value
			)
		},
		onEditDocument() {
			this.$router.push({ name: 'reports_edit_money_using', params: { id: this.$route.params.id } });
		},
		onDeleteDocument() {
			this.connection.deleteDocument(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id
			)
		},
		onRestoreDocument() {
			this.connection.restoreDocument(
				'1',
				this.$store.state.current_org_id,
				this.$route.params.id
			)
		}
	}
}
</script>

<style scoped>
@page {
	size: A4;
	margin: 0;
}

.container {
	margin: 0;
	padding: 0;
	font-family: 'Times New Roman', Times, serif;
	font-size: 12px;
	background: white;
	color: black;
	/* width: 210mm;
	height: 297mm; */
	padding: 20mm;
	box-sizing: border-box;
	display: block;
	margin: auto;
	position: relative;
}


.header {
	text-align: center;
}

.section {
	margin: 20px 0;
}

table {
	width: 100%;
	border-collapse: collapse;
}

table,
th,
td {
	border: 0.3mm solid black;
	padding: 0px 4px;
	text-align: left;
}

.right-align {
	text-align: right;
}

.signature {
	margin-top: 20px;
	text-align: right;
}

@media print {
	@page {
		size: A4;
		margin: 20mm;
	}

	body {
		margin: 0;
		font-size: 12pt;
	}

	table,
	th,
	td {
		border: 1px solid black;
		padding: 5px;
	}

	.header {
		margin-top: 0;
	}
}
</style>