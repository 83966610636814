<template>
	<div class="container">
		<h6 class="header">Отчет о финансовых результатах за <q-input class="inline" dense flat v-model="document_date">
				<template v-slot:append>
					<q-icon name="event" class="cursor-pointer">
						<q-popup-proxy cover transition-show="scale" transition-hide="scale">
							<q-date mask="DD.MM.YYYY" v-model="document_date"
								@update:model-value="(newValue) => onDateChanged(newValue)">
								<div class="row items-center justify-end">
									<q-btn v-close-popup label="Закрыть" color="primary" flat />
								</div>
							</q-date>
						</q-popup-proxy>
					</q-icon>
				</template>
			</q-input></h6>
		<div class="row" style="width: 180mm;">
			<div class="col-8">
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Организация" class="inline" style="width: 100%;" dense flat
						v-model="organisation_name" />
				</p>
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Идентификационный номер налогоплательщика" class="inline" style="width: 100%;" dense
						flat v-model="organisation_inn" />
				</p>
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Вид экономической деятельности" class="inline" style="width: 100%;" dense flat
						v-model="organisation_econ" />
				</p>
				<p style="width: 100%; padding-right: 10mm">
					<q-input label="Организационно-правовая форма/форма собственности" class="inline"
						style="width: 100%;" dense flat v-model="organisation_form" />
				</p>
				<p style="width: 100%; padding-right: 10mm">Единица измерения: <q-select v-model="currency_measure"
						:options="currency_measure_options" class="inline" dense flat />
				</p>
			</div>
			<div class="col-4">
				<table
					style="table-layout: fixed; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
					<tr>
						<td colspan="1"
							style="border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
						</td>
						<td colspan="6" style="width: 30mm; text-align: center;">Коды</td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Форма по ОКУД
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							Дата (число, месяц, год)
						</td>
						<td colspan="2"><q-input dense flat class="inline" /></td>
						<td colspan="2"><q-input dense flat class="inline" /></td>
						<td colspan="2"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКПО
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							ИНН
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКВЭД 2
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКОПФ/ОКФС
						</td>
						<td colspan="3"><q-input dense flat class="inline" /></td>
						<td colspan="3"><q-input dense flat class="inline" /></td>
					</tr>
					<tr>
						<td colspan="1"
							style="text-align: right; border-left: 0 solid white; border-bottom: 0 solid white; border-top: 0 solid white;">
							по ОКЕИ
						</td>
						<td colspan="6"><q-input dense flat class="inline" /></td>
					</tr>
				</table>
			</div>
		</div>
		<div class="section">

			<table style="table-layout: fixed; max-width: 180mm;">
				<thead>
					<tr>
						<th style="width: 25mm;">Пояснения</th>
						<th style="width: 80mm;">Наименование показателя</th>
						<th style="width: 37.5mm;">На {{ formatDate(document_date) }}г.</th>
						<th style="width: 37.5mm;">На 31 декабря 2023 г.</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>
							<q-input class="inline" v-model="profit.desc" dense flat />
						</td>
						<td>Выручка
						</td>
						<td><q-input class="inline" v-model="profit.current" dense flat /></td>
						<td><q-input class="inline" v-model="profit.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="sebest.desc" dense flat />
						</td>
						<td>Себестоимость продаж
						</td>
						<td><q-input class="inline" v-model="sebest.current" dense flat /></td>
						<td><q-input class="inline" v-model="sebest.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="val_profit.desc" dense flat />
						</td>
						<td>Валовая прибыль (убыток)
						</td>
						<td><q-input class="inline" v-model="val_profit.current" dense flat /></td>
						<td><q-input class="inline" v-model="val_profit.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="comm_costs.desc" dense flat />
						</td>
						<td>Коммерческие расходы</td>
						<td><q-input class="inline" v-model="comm_costs.current" dense flat /></td>
						<td><q-input class="inline" v-model="comm_costs.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="man_costs.desc" dense flat />
						</td>
						<td>Управленческие расходы
						</td>
						<td><q-input class="inline" v-model="man_costs.current" dense flat /></td>
						<td><q-input class="inline" v-model="man_costs.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="sell_profit.desc" dense flat />
						</td>
						<td>Прибыль (убыток) от продаж
						</td>
						<td><q-input class="inline" v-model="sell_profit.current" dense flat /></td>
						<td><q-input class="inline" v-model="sell_profit.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="another_org_prof.desc" dense flat />
						</td>
						<td>Доходы от участия в других организациях
						</td>
						<td><q-input class="inline" v-model="another_org_prof.current" dense flat /></td>
						<td><q-input class="inline" v-model="another_org_prof.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="take_percent.desc" dense flat />
						</td>
						<td>Проценты к получению
						</td>
						<td><q-input class="inline" v-model="take_percent.current" dense flat /></td>
						<td><q-input class="inline" v-model="take_percent.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="make_perc.desc" dense flat />
						</td>
						<td>Проценты к уплате
						</td>
						<td><q-input class="inline" v-model="make_perc.current" dense flat /></td>
						<td><q-input class="inline" v-model="make_perc.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="another_profit.desc" dense flat />
						</td>
						<td>Прочие доходы</td>
						<td><q-input class="inline" v-model="another_profit.current" dense flat /></td>
						<td><q-input class="inline" v-model="another_profit.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="another_costs.desc" dense flat />
						</td>
						<td>Прочие расходы</td>
						<td><q-input class="inline" v-model="another_costs.current" dense flat /></td>
						<td><q-input class="inline" v-model="another_costs.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="profit_gross.desc" dense flat />
						</td>
						<td>Прибыль (убыток) до налогообложения</td>
						<td><q-input class="inline" v-model="profit_gross.current" dense flat /></td>
						<td><q-input class="inline" v-model="profit_gross.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="profit_tax.desc" dense flat />
						</td>
						<td>Налог на прибыль</td>
						<td><q-input class="inline" v-model="profit_tax.current" dense flat /></td>
						<td><q-input class="inline" v-model="profit_tax.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="current_tax_profit.desc" dense flat />
						</td>
						<td>в т.ч. текущий налог на прибыль</td>
						<td><q-input class="inline" v-model="current_tax_profit.current" dense flat /></td>
						<td><q-input class="inline" v-model="current_tax_profit.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="delayed_tax_profit.desc" dense flat />
						</td>
						<td>отложенный налог на прибыль</td>
						<td><q-input class="inline" v-model="delayed_tax_profit.current" dense flat /></td>
						<td><q-input class="inline" v-model="delayed_tax_profit.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="another.desc" dense flat />
						</td>
						<td>Прочее</td>
						<td><q-input class="inline" v-model="another.current" dense flat /></td>
						<td><q-input class="inline" v-model="another.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="clear_profit.desc" dense flat />
						</td>
						<td>Чистая прибыль (убыток)</td>
						<td><q-input class="inline" v-model="clear_profit.current" dense flat /></td>
						<td><q-input class="inline" v-model="clear_profit.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="activ_rez.desc" dense flat />
						</td>
						<td>Результат от переоценки внеоборотных активов, не включаемый в чистую прибыль (убыток)
							периода</td>
						<td><q-input class="inline" v-model="activ_rez.current" dense flat /></td>
						<td><q-input class="inline" v-model="activ_rez.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="rez_another_operations.desc" dense flat />
						</td>
						<td>Результат от прочих операций, не включаемый в чистую прибыль (убыток) периода</td>
						<td><q-input class="inline" v-model="rez_another_operations.current" dense flat /></td>
						<td><q-input class="inline" v-model="rez_another_operations.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="tax_operations.desc" dense flat />
						</td>
						<td>Налог на прибыль от операций, результат которых не включается в чистую прибыль (убыток)
							периода</td>
						<td><q-input class="inline" v-model="tax_operations.current" dense flat /></td>
						<td><q-input class="inline" v-model="tax_operations.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="total_fin_rez.desc" dense flat />
						</td>
						<td>Совокупный финансовый результат периода</td>
						<td><q-input class="inline" v-model="total_fin_rez.current" dense flat /></td>
						<td><q-input class="inline" v-model="total_fin_rez.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="basic_profit_oblig.desc" dense flat />
						</td>
						<td>Справочно Базовая прибыль (убыток) на акцию</td>
						<td><q-input class="inline" v-model="basic_profit_oblig.current" dense flat /></td>
						<td><q-input class="inline" v-model="basic_profit_oblig.previous" dense flat /></td>
					</tr>
					<tr>
						<td>
							<q-input class="inline" v-model="profit_oblig.desc" dense flat />
						</td>
						<td>Разводненная прибыль (убыток) на акцию</td>
						<td><q-input class="inline" v-model="profit_oblig.current" dense flat /></td>
						<td><q-input class="inline" v-model="profit_oblig.previous" dense flat /></td>
					</tr>
				</tbody>
			</table>
		</div>
	</div>
	<div style="margin-top: 10mm;">
		<q-btn color="primary" icon="save" label="Сохранить" @click="onSave" />
	</div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

const currency_measure_options = [
	{ label: 'тыс.руб.', value: 'th_rub' },
];

export default {
	name: "create_document_report_fin_rez",
	data() {
		this.emitter.on('didCreateNewDocument', (data) => {
			console.log(data);
			this.$q.loading.hide();
			if (data.error) {
				this.$q.dialog({
					title: 'Ошибка',
					message: 'Документ не был создан',
					cancel: false,
					persistent: true
				}).onOk(() => {
				}).onCancel(() => {
					console.log('cancel')
				}).onDismiss(() => {
					console.log('dismiss')
				})
			} else {
				this.$q.notify('Документ был создан');
				this.$router.push({ name: 'reports_list', params: {} });
			}
		})
		this.emitter.on('didReceiveNewDocumentNumber', (data) => {
			this.in_org_number = data.number;
			this.step = 2;
		});
		this.emitter.on('didReceiveAgentInfoByInn', (data) => {
			console.log(data, 'agent')
			if (data.request_id === 'rec_inn') {
				this.rec = data.info.name.short_with_opf;
				this.rec_kpp = data.info.kpp;
			}
			if (data.request_id === 'plat_inn') {
				this.plat = data.info.name.short_with_opf;
				this.plat_kpp = data.info.kpp;
			}
		})
		if (this.connection.isAuthenticated) {
			this.connection.getNewDocumentNumber(
				'1',
				this.$store.state.current_org_id,
				'report'
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getNewDocumentNumber(
					'1',
					this.$store.state.current_org_id,
					'report'
				);
			})
		}
		return {
			step: ref(1),
			document_type: ref('report'),
			in_org_number: ref(null),
			organisation_name: ref(null),
			organisation_inn: ref(null),
			organisation_econ: ref(null),
			organisation_form: ref(null),
			document_date_unix: ref(0),
			document_date: ref(null),
			currency_measure_options: ref(currency_measure_options),
			currency_measure: ref(currency_measure_options[0]),
			profit: ref({ current: 0, previous: 0, desc: '' }),
			sebest: ref({ current: 0, previous: 0, desc: '' }),
			val_profit: ref({ current: 0, previous: 0, desc: '' }),
			comm_costs: ref({ current: 0, previous: 0, desc: '' }),
			man_costs: ref({ current: 0, previous: 0, desc: '' }),
			sell_profit: ref({ current: 0, previous: 0, desc: '' }),
			another_org_prof: ref({ current: 0, previous: 0, desc: '' }),
			take_percent: ref({ current: 0, previous: 0, desc: '' }),
			make_perc: ref({ current: 0, previous: 0, desc: '' }),
			another_profit: ref({ current: 0, previous: 0, desc: '' }),
			another_costs: ref({ current: 0, previous: 0, desc: '' }),
			profit_gross: ref({ current: 0, previous: 0, desc: '' }),
			profit_tax: ref({ current: 0, previous: 0, desc: '' }),
			current_tax_profit: ref({ current: 0, previous: 0, desc: '' }),
			delayed_tax_profit: ref({ current: 0, previous: 0, desc: '' }),
			another: ref({ current: 0, previous: 0, desc: '' }),
			clear_profit: ref({ current: 0, previous: 0, desc: '' }),
			activ_rez: ref({ current: 0, previous: 0, desc: '' }),
			rez_another_operations: ref({ current: 0, previous: 0, desc: '' }),
			tax_operations: ref({ current: 0, previous: 0, desc: '' }),
			total_fin_rez: ref({ current: 0, previous: 0, desc: '' }),
			basic_profit_oblig: ref({ current: 0, previous: 0, desc: '' }),
			profit_oblig: ref({ current: 0, previous: 0, desc: '' }),
		}
	},
	methods: {
		onDateChanged(newValue) {
			this.document_date_unix = moment(newValue, 'DD.MM.YYYY').unix()
		},
		checkAgentBy(bik_val, field_id) {
			const bik = bik_val.replace(/\s/g, "");
			console.log(bik, field_id)
			if (bik.length < 9) {
				return;
			}
			this.connection.getAgentInfoByBik(
				field_id,
				bik
			);
		},
		checkAgentByInn(inn_val, field_id) {
			const inn = inn_val.replace(/\s/g, "");
			console.log(inn, field_id)
			if (inn.length < 9) {
				return;
			}
			this.connection.getAgentInfoByInn(
				field_id,
				inn
			);
		},
		formatDate(date) {
			return moment.unix(date).format('DD.MM.YYYY');
		},
		onSave() {
			const body = {
				organisation_name: this.organisation_name,
				organisation_inn: this.organisation_inn,
				organisation_econ: this.organisation_econ,
				organisation_form: this.organisation_form,
				organisation_address: this.organisation_address,
				organisation_audit_id: this.organisation_audit_id,
				organisation_audit_name: this.organisation_audit_name,
				organisation_audit_num: this.organisation_audit_num,
				audit: this.audit,
				currency_measure: this.currency_measure,
				profit: this.profit,
				sebest: this.sebest,
				val_profit: this.val_profit,
				comm_costs: this.comm_costs,
				man_costs: this.man_costs,
				sell_profit: this.sell_profit,
				another_org_prof: this.another_org_prof,
				take_percent: this.take_percent,
				make_perc: this.make_perc,
				another_profit: this.another_profit,
				another_costs: this.another_costs,
				profit_gross: this.profit_gross,
				profit_tax: this.profit_tax,
				current_tax_profit: this.current_tax_profit,
				delayed_tax_profit: this.delayed_tax_profit,
				another: this.another,
				clear_profit: this.clear_profit,
				activ_rez: this.activ_rez,
				rez_another_operations: this.rez_another_operations,
				tax_operations: this.tax_operations,
				total_fin_rez: this.total_fin_rez,
				basic_profit_oblig: this.basic_profit_oblig,
				profit_oblig: this.profit_oblig,
				document_date: this.document_date_unix,
				in_org_number: this.in_org_number,
				document_type: 'report',
				report_type: 'fin_rez'
			};
			this.$q.loading.show();
			this.connection.createNewDocument(
				'3',
				this.$store.state.current_org_id,
				body
			);

		}

	}
}
</script>

<style scoped>
@page {
	size: A4;
	margin: 0;
}

.container {
	margin: 0;
	padding: 0;
	font-family: 'Times New Roman', Times, serif;
	font-size: 12px;
	background: white;
	color: black;
	/* width: 210mm;
	height: 297mm; */
	padding: 20mm;
	box-sizing: border-box;
	display: block;
	margin: auto;
	position: relative;
}


.header {
	text-align: center;
}

.section {
	margin: 20px 0;
}

table {
	width: 100%;
	border-collapse: collapse;
}

table,
th,
td {
	border: 0.3mm solid black;
	padding: 0px 4px;
	text-align: left;
}

.right-align {
	text-align: right;
}

.signature {
	margin-top: 20px;
	text-align: right;
}

@media print {
	@page {
		size: A4;
		margin: 20mm;
	}

	body {
		margin: 0;
		font-size: 12pt;
	}

	table,
	th,
	td {
		border: 1px solid black;
		padding: 5px;
	}

	.header {
		margin-top: 0;
	}
}
</style>
