<template>
<div class="row">

</div>
</template>

<script>
import { ref } from 'vue'


export default {
  name: "my_page",
  data() {
    this.emitter.on('didReceoveUserInfoAcrossOrg', (data) => {
      this.$store.commit('userInfoAcrossOrg', data);
      this.user_info = data.user;
      if (data.user !== undefined) {
        if (data.user.organisations.length > 0) {
          this.can_open_finances = data.user.role.permissions.open_finance;
          this.can_open_org_structure = data.user.role.permissions.edit_roles;
          this.routeToHome();
        }
      }
    });
    this.emitter.on('receiveUserInfo', (data) => {
      this.user_info = data;
    });
    if (this.connection.isAuthenticated) {
      this.connection.getUserInfoAcrossOrg('1', this.$store.state.current_org_id);
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getUserInfoAcrossOrg('1', this.$store.state.current_org_id);
      });
    }
    return {
      user_info: ref(null),
      can_open_finances: ref(false),
      can_open_org_structure: ref(false),
    }
  },
  methods: {
    routeToHome() {
      console.log(this.user_info)
      if (this.user_info.organisations.length > 0) {
        if (this.can_open_org_structure) {
          this.$router.push({ name: 'org_main', params: {} });
        } else if (this.can_open_finances) {
          this.$router.push({ name: 'bills_plan', params: {} });
        } else {
          this.$router.push({ name: 'profile_edit', params: {} });
        }
      } else {
        this.$router.push({ name: 'profile_edit', params: {} });
      }
    },
  }
}
</script>

<style scoped>

</style>