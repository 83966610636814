<template>
	<div class="row">
		<dir class="q-ma-sm">
			<router-view />
		</dir>
	</div>
</template>

<script>

export default {
	name: "report_create_root",
}
</script>

<style scoped></style>